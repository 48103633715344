var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.submitForm}},[_c('a-row',{staticClass:"files",attrs:{"gutter":20}},[_c('a-row',{attrs:{"id":"files","gutter":20}},[_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":6}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Frente do Cartão")]),_vm._v(" "),(!_vm.doc.front)?_c('div',[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=opportunities&id=${_vm.tempOpportunity.id}&sub-folder=credit-card-front`,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  },"disabled":!_vm.edit},on:{"change":_vm.uploadedFile},model:{value:(_vm.doc.front),callback:function ($$v) {_vm.$set(_vm.doc, "front", $$v)},expression:"doc.front"}},[_c('a-button',{attrs:{"disabled":!_vm.edit,"type":"dashed","size":"large"}},[_c('a-icon',{attrs:{"type":"credit-card"}})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.doc.front)?_c('div',{staticClass:"file-link relative"},[(_vm.doc.front)?_c('a',{attrs:{"href":_vm.doc.front,"target":"_blank"}},[(_vm.checkFileType(_vm.doc.front))?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.doc.front}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"credit-card"}})],1):_vm._e(),_vm._v(" "),(_vm.doc.front && _vm.edit)?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('credit-card-front')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'opportunity_files_credit_card_front_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'opportunity_files_credit_card_front_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de validade")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'opportunity_files_credit_card_front_expires_at',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'opportunity_files_credit_card_front_expires_at',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled":!_vm.edit}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":6}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Verso do Cartão")]),_vm._v(" "),(!_vm.doc.back)?_c('div',[_c('a-upload',{attrs:{"multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=opportunities&id=${_vm.tempOpportunity.id}&sub-folder=credit-card-back`,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  },"disabled":!_vm.edit},on:{"change":_vm.uploadedFile},model:{value:(_vm.doc.back),callback:function ($$v) {_vm.$set(_vm.doc, "back", $$v)},expression:"doc.back"}},[_c('a-button',{attrs:{"type":"dashed","disabled":!_vm.edit,"size":"large"}},[_c('a-icon',{attrs:{"type":"credit-card"}})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.doc.back)?_c('div',{staticClass:"file-link relative"},[(_vm.doc.back)?_c('a',{attrs:{"href":_vm.doc.back,"target":"_blank"}},[(_vm.checkFileType(_vm.doc.back))?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.doc.back}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"credit-card"}})],1):_vm._e(),_vm._v(" "),(_vm.doc.back && _vm.edit)?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('credit-card-back')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'opportunity_files_credit_card_back_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'opportunity_files_credit_card_back_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de validade")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'opportunity_files_credit_card_back_expires_at',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'opportunity_files_credit_card_back_expires_at',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY","showToday":false,"allowClear":true,"disabled":!_vm.edit}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{staticClass:"mb-20",attrs:{"span":6}},[_c('div',{staticClass:"label mb-10"},[_vm._v("Slip de pagamento")]),_vm._v(" "),(!_vm.doc.slip)?_c('div',[_c('a-upload',{attrs:{"multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=opportunities&id=${_vm.tempOpportunity.id}&sub-folder=credit-card-slip`,"disabled":!_vm.edit,"headers":{
                    authorization: `Bearer ${this.$store.state.token}`,
                  }},on:{"change":_vm.uploadedFile},model:{value:(_vm.doc.slip),callback:function ($$v) {_vm.$set(_vm.doc, "slip", $$v)},expression:"doc.slip"}},[_c('a-button',{attrs:{"type":"dashed","disabled":!_vm.edit,"size":"large"}},[_c('a-icon',{attrs:{"type":"profile"}})],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.doc.slip)?_c('div',{staticClass:"file-link relative"},[(_vm.doc.slip)?_c('a',{attrs:{"href":_vm.doc.slip,"target":"_blank"}},[(_vm.checkFileType(_vm.doc.slip))?_c('img',{staticStyle:{"max-width":"260px"},attrs:{"src":_vm.doc.slip}}):_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"38px"},attrs:{"type":"profile"}})],1):_vm._e(),_vm._v(" "),(_vm.doc.slip && _vm.edit)?_c('a-icon',{staticClass:"close-circle",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removefile('credit-card-slip')}}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('div',{staticClass:"label"},[_vm._v("Data de criação")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
                    'opportunity_files_credit_card_slip_created',
                    {
                      rules: [
                        {
                          required: false,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'opportunity_files_credit_card_slip_created',\n                    {\n                      rules: [\n                        {\n                          required: false,\n                          message: 'Obrigatório',\n                        },\n                      ],\n                    },\n                  ]"}],staticClass:"travel-input",attrs:{"placeholder":"Selecione","format":"DD/MM/YYYY HH:mm","showToday":false,"allowClear":false,"disabled":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"actions",attrs:{"span":24}},[(_vm.edit)?_c('a-button',{staticClass:"ml-10 cancel",attrs:{"type":"primary"},on:{"click":function($event){_vm.edit = false}}},[_c('a-icon',{attrs:{"type":"close"}})],1):_vm._e(),_vm._v(" "),(!_vm.edit)?_c('a-button',{staticClass:"ml-10 edit",attrs:{"type":"primary"},on:{"click":function($event){_vm.edit = true}}},[_c('a-icon',{attrs:{"type":"edit"}})],1):_vm._e(),_vm._v(" "),(_vm.edit)?_c('a-button',{staticClass:"ml-10 save",staticStyle:{"padding":"0px 6px"},attrs:{"html-type":"submit","type":"primary","loading":_vm.loading}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],attrs:{"src":require("@/assets/images/dashboard/sales/disket.png"),"alt":"save"}})]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }